import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DogInYou from './Screens/DogInYou';

function App() {
  return (
    <Routes>
      <React.Fragment>
        <Route path="/" element={<DogInYou />} />
      </React.Fragment>
      /</Routes>
  );
}

export default App;
