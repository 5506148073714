import React, { useState, useRef, useEffect } from "react";
import "../Css/DogInYou.css";
import addBtn from "../Assets/addBtn.png";
import saveBtn from "../Assets/saveBtn.png";
import shadeDog from "../Assets/shadeDog2.png";
import domToImage from 'dom-to-image';

const DogInYou = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [showBorder, setShowBorder] = useState(true); // State for controlling border visibility
    const canvasRef = useRef(null);
    const pinchDistanceRef = useRef(null);

    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!isMobile) {
            const handleWheel = (event) => {
                const zoomSpeed = 0.08;
                event.preventDefault();

                if (event.deltaY < 0) {
                    // Zoom in
                    setScale(scale + zoomSpeed);
                } else {
                    // Zoom out
                    if (scale - zoomSpeed > 0) {
                        setScale(scale - zoomSpeed);
                    }
                }
            };

            document.addEventListener('wheel', handleWheel);

            return () => {
                document.removeEventListener('wheel', handleWheel);
            };
        }
    }, [scale]);

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handlePinchStart = (event) => {
        const touches = event.touches;
        if (touches.length === 2) {
            const dx = touches[1].clientX - touches[0].clientX;
            const dy = touches[1].clientY - touches[0].clientY;
            pinchDistanceRef.current = Math.sqrt(dx * dx + dy * dy);
        }
    };

    const handlePinchMove = (event) => {
        const touches = event.touches;
        if (touches.length === 2 && pinchDistanceRef.current) {
            const dx = touches[1].clientX - touches[0].clientX;
            const dy = touches[1].clientY - touches[0].clientY;
            const pinchDistance = Math.sqrt(dx * dx + dy * dy);

            const zoomDelta = (pinchDistance - pinchDistanceRef.current) * 0.01;
            const newScale = Math.max(0.1, scale + zoomDelta);

            setScale(newScale);
            pinchDistanceRef.current = pinchDistance;
        }
    };

    const handlePinchEnd = () => {
        pinchDistanceRef.current = null;
    };

    const handleMouseDown = (event) => {
        setDragging(true);
        setOffset({
            x: event.clientX - position.x,
            y: event.clientY - position.y,
        });
    };

    const handleMouseMove = (event) => {
        if (dragging) {
            setPosition({
                x: event.clientX - offset.x,
                y: event.clientY - offset.y,
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleTouchStart = (event) => {
        if (event.touches.length === 1) {
            handleMouseDown(event.touches[0]);
        } else if (event.touches.length === 2) {
            handlePinchStart(event);
        }
    };

    const handleTouchMove = (event) => {
        if (event.touches.length === 1) {
            handleMouseMove(event.touches[0]);
        } else if (event.touches.length === 2) {
            handlePinchMove(event);
        }
    };

    const handleTouchEnd = () => {
        handleMouseUp();
        handlePinchEnd();
    };

    const handleSaveImage = () => {
        const node = canvasRef.current;

        setShowBorder(false); // Hide the border before saving the image

        domToImage.toBlob(node)
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);

               // Create a temporary link element
               const link = document.createElement('a');
               link.href = url;
               link.download = 'doginme_output.png';

               // Detect if the device is iOS
               const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

               if (isIOS) {
                   // Open the image in a new tab
                   const newWindow = window.open(url, '_blank');
                   if (newWindow) {
                       newWindow.focus();
                   }
               } else {
                   // Simulate click on the link to trigger download
                   link.click();
               }

                // Remove the temporary link
                window.URL.revokeObjectURL(url);

            })
            .catch((error) => {
                console.error('Error saving image:', error);
            })
            .finally(() => {
                setShowBorder(true); // Show the border again after saving the image
            });
    };


    const handleZoomIn = () => {
        setScale(scale + 0.1); // Increase the scale by 0.1 (you can adjust this value)
    };

    const handleZoomOut = () => {
        if (scale - 0.1 > 0) {
            setScale(scale - 0.1); // Decrease the scale by 0.1 (you can adjust this value)
        }
    };

    const handleMove = (direction) => {
        let newPosition = { ...position };

        switch (direction) {
            case 'up':
                newPosition.y -= 10; // Adjust the value as needed for the movement step
                break;
            case 'down':
                newPosition.y += 10; // Adjust the value as needed for the movement step
                break;
            case 'left':
                newPosition.x -= 10; // Adjust the value as needed for the movement step
                break;
            case 'right':
                newPosition.x += 10; // Adjust the value as needed for the movement step
                break;
            default:
                break;
        }

        setPosition(newPosition);
    };

    return (
        <div className="main-div">
            {
                    <div>
                        <p className="header-text">GET THAT DOG IN YOU</p>
                        <div
                            className="center-div"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <div
                                className="box-div"
                                style={{
                                    width: "400px",
                                    height: "500px",
                                    border: showBorder ? "10px solid black" : "none",
                                    position: "relative",
                                    borderBottom: showBorder ? "20px solid black" : "none",
                                    cursor: dragging ? "grabbing" : "grab",
                                    overflow: "hidden",
                                    touchAction: "none", // Disable default touch actions
                                }}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                ref={canvasRef}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    {selectedImage && (
                                        <img
                                            style={{
                                                objectFit: "contain",
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                zIndex: -1, // Send behind the shadeDog image
                                            }}
                                            src={selectedImage}
                                            alt="Selected"
                                        />
                                    )}
                                    <img
                                        style={{
                                            transform: `scale(${scale})`,
                                            transformOrigin: "center",
                                            position: "absolute",
                                            top: `${position.y}px`,
                                            left: `${position.x}px`,
                                            // opacity: "0.7"
                                        }}
                                        src={shadeDog}
                                        alt="Selected"
                                    />
                                </div>
                            </div>
                            <div className="two-btns" style={{ marginLeft: "70px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <label className="label" htmlFor="imageInput">
                                    <img
                                        className="add-img-btn"
                                        src={addBtn}
                                        alt="Add"
                                        style={{ cursor: "pointer" }}
                                    />
                                </label>
                                <input
                                    id="imageInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleImageSelect}
                                />
                                {selectedImage !== null &&
                                    <img
                                        className="save-img-btn"
                                        onClick={handleSaveImage}
                                        src={saveBtn}
                                        alt="Save"
                                        style={{ cursor: "pointer", marginTop: "20px" }}
                                    />
                                }
                                <div style={{ marginTop: "3%", display: "flex", justifyContent: "center", flexDirection: "column" }} className="controls">
                                    <p style={{ fontWeight: 500 }}>Overlay Placement Controls</p>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", cursor: "pointer" }} onClick={() => handleMove('up')}>&uarr;</button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1.5rem' }}>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", fontSize: "20px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={handleZoomIn}>+</button>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", cursor: "pointer" }} onClick={() => handleMove('left')}>&larr;</button>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", cursor: "pointer" }} onClick={() => handleMove('right')}>&rarr;</button>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", fontSize: "24px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={handleZoomOut}>-</button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button style={{ width: "35px", height: "35px", borderRadius: "50%", color: "white", background: "black", cursor: "pointer" }} onClick={() => handleMove('down')}>&darr;</button>
                                    </div>
                                    <div className="controls">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default DogInYou;
